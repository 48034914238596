.Menu {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    z-index: 100;
  }
  
  .Button {
    padding: 40px 80px;
    color: var(--font-color);
    font-size: 2em;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
    cursor: pointer;
  }
  