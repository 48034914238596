.Board {
    margin: 2em auto;
    display: grid;
    grid-gap: 2px;
    width: 22vw;
    height: 45vw;
    background: rgb(234, 234, 234);
    border: 10px solid rgb(32, 0, 64);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  