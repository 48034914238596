.GameStats {
    position: absolute;
    width: 22vw;
    list-style-type: none;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .GameStats__left {
    right: 0;
    top: 22vh;
    text-align: left;
  }
  
  .GameStats__right {
    right: 0;
    bottom: 0;
    text-align: left;
  }
  
  .GameStats .value {
    font-size: 2rem;
    margin-bottom: 0.5em;
    color: rgb(68, 68, 68);
  }
  